import {Button, Modal, Spin} from "antd"
import {useEffect, useState} from "react"
import {fetchSecure} from "shared/net"
import {toDisplayDate} from "shared/utils"

interface FieldWorkResult {
    items: Session[]
}

interface Session {
    id: number
    chatId: number
    currentActive: boolean
    sessionData: SessionData
    completed: boolean
    dateCreated: string
    images: SessionImage[]
}

interface SessionImage {
    id: number
    context: string
    size: number
}

interface SessionData {
    CurrentStep: string
    Firstname: string
    Lastname: string
    BillingEntity: string
    UnitNumber: string
    WaterMeterNumber?: string
    WaterMeterType?: string
    ElectricityMeterNumber?: string
    ElectricityMeterType?: string
    ContactName: string
    ContactNumber: string
}


export default function FieldworkOverviewPage() {

    const [completeSessions, setCompleteSessions] = useState<Session[] | undefined>(undefined)
    const [incompleteSessions, setIncompleteSessions] = useState<Session[] | undefined>(undefined)
    const [selectedImage, setSelectedImage] = useState<SessionImage | undefined>(undefined)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showImage = (image: SessionImage) => {
        setSelectedImage(image);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setSelectedImage(undefined);
        setTimeout(() => setIsModalOpen(false), 30);
    };

    const handleCancel = () => {
        setSelectedImage(undefined);
        setTimeout(() => setIsModalOpen(false), 30);
    };

    useEffect(() => {
        fetchSecure('/api/1/fieldwork/sessions')
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
                throw new Error('Failed to fetch fieldwork sessions')
            })
            .then((data: FieldWorkResult) => {
                setCompleteSessions(data.items.filter((session: any) => session.completed))
                setIncompleteSessions(data.items.filter((session: any) => !session.completed))
            })
            .catch(error => {
                console.error(error)
            })
    }, [])

    return <>
        <Modal title="Image Preview" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={550}>
            {selectedImage && <>
                <img src={`/api/1/fieldwork/images/${selectedImage.id}`} style={{width: 500}}/>
                <div><a href={`/api/1/fieldwork/images/${selectedImage.id}`} target="_blank" rel="noreferrer">View full
                    size</a></div>
            </>}
        </Modal>
        <h1>Fieldwork Bot Sessions</h1>
        <h2>Completed Sessions</h2>
        {!completeSessions && <Spin/>}
        {completeSessions && completeSessions.length === 0 && <div>No completed sessions</div>}
        {completeSessions && completeSessions.length > 0 && <table className="full-width bordered shadow data">
            <thead>
            <tr>
                <th>Date created</th>
                <th>First name</th>
                <th>Last name</th>
                <th>Billing Entity</th>
                <th>Unit</th>
                <th>Water Meter</th>
                <th>Water Meter Images</th>
                <th>Elec Meter</th>
                <th>Elec Meter Images</th>
                <th>Contact Name</th>
                <th>Contact Number</th>
            </tr>
            </thead>
            {completeSessions.map((session: Session) => <>
                <tr>
                    <td>{toDisplayDate(session.dateCreated)}</td>
                    <td>{session.sessionData.Firstname}</td>
                    <td>{session.sessionData.Lastname}</td>
                    <td>{session.sessionData.BillingEntity}</td>
                    <td>{session.sessionData.UnitNumber}</td>
                    <td>
                        {session.sessionData.WaterMeterNumber ? <>
                            {session.sessionData.WaterMeterNumber}
                            &nbsp;({session.sessionData.WaterMeterType})
                        </> : '-'}
                    </td>
                    <td>
                        {session.images.filter((image: SessionImage) => image.context === 'water_meter_photo')
                            .map((image: SessionImage, index) => <Button type="link"
                                                                         onClick={() => showImage(image)}>Image{index + 1}</Button>)}
                    </td>
                    <td>
                        {session.sessionData.ElectricityMeterNumber ? <>
                            {session.sessionData.ElectricityMeterNumber}
                            &nbsp;({session.sessionData.ElectricityMeterType})
                        </> : '-'}
                    </td>
                    <td>
                        {session.images.filter((image: SessionImage) => image.context === 'electricity_meter_photos')
                            .map((image: SessionImage, index) => <Button type="link"
                                                                         onClick={() => showImage(image)}>Image{index + 1}</Button>)}
                    </td>
                    <td>{session.sessionData.ContactName}</td>
                    <td>{session.sessionData.ContactNumber}</td>
                </tr>
            </>)}
        </table>}
        <h2>Incomplete Sessions</h2>
        {!incompleteSessions && <Spin/>}
        {incompleteSessions && incompleteSessions.length === 0 && <div>No incomplete sessions</div>}
        {incompleteSessions && incompleteSessions.length > 0 && <table className="full-width bordered shadow data">
            <thead>
            <tr>
                <th>Date created</th>
                <th>Current Step</th>
                <th>First name</th>
                <th>Last name</th>
                <th>Billing Entity</th>
                <th>Unit</th>
                <th>Water Meter</th>
                <th>Water Meter Images</th>
                <th>Elec Meter</th>
                <th>Elec Meter Images</th>
                <th>Contact Name</th>
            </tr>
            </thead>
            {incompleteSessions.map((session: Session) => <>
                <tr>
                    <td>{toDisplayDate(session.dateCreated)}</td>
                    <td>{session.sessionData.CurrentStep}</td>
                    <td>{session.sessionData.Firstname}</td>
                    <td>{session.sessionData.Lastname}</td>
                    <td>{session.sessionData.BillingEntity}</td>
                    <td>{session.sessionData.UnitNumber}</td>
                    <td>
                        {session.sessionData.WaterMeterNumber ? <>
                            {session.sessionData.WaterMeterNumber}
                            &nbsp;({session.sessionData.WaterMeterType})
                        </> : '-'}
                    </td>
                    <td>
                        {session.images.filter((image: SessionImage) => image.context === 'water_meter_photo')
                            .map((image: SessionImage, index) => <Button type="link"
                                                                         onClick={() => showImage(image)}>Image{index + 1}</Button>)}
                    </td>
                    <td>
                        {session.sessionData.ElectricityMeterNumber ? <>
                            {session.sessionData.ElectricityMeterNumber}
                            &nbsp;({session.sessionData.ElectricityMeterType})
                        </> : '-'}
                    </td>
                    <td>
                        {session.images.filter((image: SessionImage) => image.context === 'electricity_meter_photos')
                            .map((image: SessionImage, index) => <Button type="link"
                                                                         onClick={() => showImage(image)}>Image{index + 1}</Button>)}
                    </td>
                    <td>{session.sessionData.ContactName}</td>
                </tr>
            </>)}
        </table>}

    </>
}