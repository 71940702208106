import {CopyOutlined} from "@ant-design/icons";
import {message} from "antd";

function copyText(api: any, text: string) {
    navigator.clipboard.writeText(text);
    api.success({
        content: 'Copied to clipboard',
    });
}

export default function CopyableText({text}: { text: string }) {

    const [api, contextHolder] = message.useMessage();

    return <>
        {contextHolder}
        {text}
        <CopyOutlined
            style={{marginLeft: 10}}
            title="Copy to clipboard"
            onClick={() => copyText(api, text)}/>
    </>

}