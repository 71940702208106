import React, { useRef, useEffect } from 'react';
import { init, getInstanceByDom } from 'echarts';
// import type { EChartOption } from 'echarts';

interface ReactEChartsProps {
    option: any;
    style?: React.CSSProperties;
}

const ReactECharts: React.FC<ReactEChartsProps> = ({ option, style }) => {
    const chartRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let chart = getInstanceByDom(chartRef.current!);
        if (!chart) {
            chart = init(chartRef.current!);
        }
        chart.setOption(option);

        const resizeChart = () => {
            chart?.resize();
        };

        window.addEventListener('resize', resizeChart);

        return () => {
            window.removeEventListener('resize', resizeChart);
            chart?.dispose();
        };
    }, [option]);

    return <div ref={chartRef} style={{ width: '100%', height: '200px', ...style }} />;
};

export default ReactECharts;