import {Button, Card, DatePicker, Form, Input, message, Popconfirm} from "antd";
import {ISupportMeterInfo} from "../overview-types";
import {formatCurrency, toShortDate} from "shared/utils";
import {fetchSecure} from "shared/net";
import EditableValue from "../../../../components/EditableValue";
import EditableDateValue from "../../../../components/EditableDateValue";

interface PendingCollectionsWidgetProps {
    info: ISupportMeterInfo;
    onInfoRefresh: () => void;
}

type FieldType = {
    reason: string;
    amount: string;
    startDate: string;
}


export default function PendingCollectionsWidget({info, onInfoRefresh}: PendingCollectionsWidgetProps) {

    const [api, contextHolder] = message.useMessage();
    const [form] = Form.useForm();

    async function cancelCollection(id: any) {
        const response = await fetchSecure(`/api/1/collections/${id}`, {
            method: 'DELETE',
        });

        if (response.ok) {
            api.open({
                type: 'success',
                content: 'Collection canceled',
            });
            onInfoRefresh();
        } else {
            api.open({
                type: 'error',
                content: 'Failed to cancel collection',
            });
        }
    }

    const onFinish = async (values: FieldType) => {
        const response = await fetchSecure('/api/1/collections', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                reason: values.reason,
                amount: values.amount,
                startDate: values.startDate,
                accountId: info.account.id,
            }),
        });

        if (response.ok) {
            api.open({
                type: 'success',
                content: 'Collection added',
            });
            form.resetFields();
            onInfoRefresh();
        } else {
            api.open({
                type: 'error',
                content: 'Failed to add collection',
            });
        }
    }

    return <Card bordered={false} title="Pending Collections" style={{marginTop: 5}}>
        {info.collections.length !== 0 ? <>
            {contextHolder}
            <table className="full-width bordered">
                <thead>
                <tr style={{backgroundColor: '#FCC'}}>
                    <th>Reason</th>
                    <th>Amount</th>
                    <th>Due</th>
                    <th style={{width: 50}}></th>
                </tr>
                </thead>
                <tbody>
                {info.collections?.map((collection, index) => (
                    <tr key={index}>
                        <td>
                            <EditableValue prop={{
                                id: collection.id,
                                type: 'string',
                                field: 'reason',
                                value: collection.reason,
                                name: 'Reason',
                                entity: 'financials.collections'
                            }}/>
                        </td>
                        <td style={{textAlign: 'right'}}>
                            {formatCurrency(collection.remainingAmount)}
                        </td>
                        <td style={{textAlign: 'right'}}>
                            {toShortDate(collection.dateDue)}
                        </td>
                        <td>
                            <Popconfirm
                                title="Cancel collection?"
                                description="Are you sure to cancel this collection?"
                                onConfirm={() => cancelCollection(collection.id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button size="small" type="primary" danger>Cancel</Button>
                            </Popconfirm>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </> : <div><i>No pending collections</i></div>}
        <h4 style={{marginBottom: 0}}>Add an ad-hoc collection</h4>
        <Form
            name="newCollection"
            autoComplete="off"
            onFinish={onFinish}
            form={form}
        >

            <table className="full-width quick-form">
                <tbody>
                <tr>
                    <td colSpan={3}>
                        <Form.Item<FieldType> name="reason">
                            <Input placeholder="Reason..."/>
                        </Form.Item>
                    </td>
                </tr>
                <tr>
                    <td>
                        <Form.Item<FieldType> name="amount">
                            <Input placeholder="Incl. Amount..."/>
                        </Form.Item>
                    </td>
                    <td>
                        <Form.Item<FieldType> name="startDate">
                            <DatePicker placeholder="Due date..." style={{width: 130}}/>
                        </Form.Item>
                    </td>
                    <td style={{width: 50}}><Button type="primary" htmlType="submit">Add</Button></td>
                </tr>
                </tbody>
            </table>
        </Form>
    </Card>
}
