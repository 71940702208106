import "./App.css";
import {Outlet, useNavigate, useLocation} from "react-router-dom";
import {Breadcrumb, Layout, Menu, theme} from "antd";
import {menuItems, getBreadcrumbs, getRoleForPath} from "shared/menu-info";
import {Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {hasRole} from "shared/utils";
import useAuth from "shared/use-auth";
import {setSearchQuery} from "../../stores/app-slice";

const {Search} = Input;
const {Header, Content, Footer} = Layout;

function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const {roles} = useSelector((state: any) => state.user);
    const {globalSearchQuery} = useSelector((state: any) => state.app);
    const dispatch = useDispatch();

    useAuth();


    const {
        token: {colorBgContainer, borderRadiusLG},
    } = theme.useToken();
    const crumbs = getBreadcrumbs(location.pathname);
    const onSearch = (value: string) => {
        // set global search query value
        dispatch(setSearchQuery({globalSearchQuery: value} as any));
        navigate("/global-search-results");
    }

    return (
        <Layout>
            <Header
                style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "24px",
                    paddingRight: 0,
                }}
            >
                <div
                    className="demo-logo"
                    style={{color: "white", fontSize: "16pt"}}
                >
                    PPEX Engage
                    <Search
                        placeholder="Search..."
                        defaultValue={globalSearchQuery}
                        onSearch={onSearch}
                        style={{width: 200, marginLeft: 18, marginTop: 18}}
                    />
                </div>
                <Menu
                    mode="horizontal"
                    defaultSelectedKeys={[location.pathname]}
                    selectedKeys={[location.pathname]}
                    items={menuItems.filter((item: any) =>
                        hasRole(roles, getRoleForPath(item.key))
                    )}
                    style={{flex: 1, minWidth: 0, marginLeft: "18px"}}
                    onSelect={({key}) => navigate(key)}
                />
            </Header>
            <Content style={{padding: "0 24px"}}>
                <Breadcrumb style={{margin: "12px 0"}} items={crumbs}/>
                <div
                    style={{
                        background: colorBgContainer,
                        minHeight: 280,
                        padding: "12px 24px",
                        borderRadius: borderRadiusLG,
                        border: "1px solid #CCC",
                        marginBottom: 20,
                        boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.2)",
                    }}
                    className="page-content"
                >
                    <Outlet/>
                </div>
            </Content>
            <Footer style={{textAlign: "center"}}>
                PPEX Engage ©{new Date().getFullYear()} PPEX
            </Footer>
        </Layout>
    );
}

export default App;
