import {IOverviewHistoryItemPropType} from "./overview-history-types";

interface MeterCreatedHistoryItemData {
    account_id: string
    account_number: string
    meter_id: string
    meter_number: string
    sgc: string
    krn: string
    ti: string
    subclass: string
}

function getSubclassName(subclass: string) {
    switch (+subclass) {
        case 0:
            return 'Electricity (0)';
        case 1:
            return 'Water (1)';
        case 3:
            return 'Gas (2)';
        default:
            return subclass;
    }
}


export default function MeterCreatedHistoryItem({entry}: IOverviewHistoryItemPropType) {
    const item: MeterCreatedHistoryItemData = entry.eventData;

    return <div>
        <table>
            <tbody>
            <tr>
                <th>Meter Created:</th>
                <th>Subclass</th>
                <td>{getSubclassName(item.subclass)}</td>
                <th>SGC</th>
                <td>{item.sgc}</td>
                <th>TI</th>
                <td>{item.ti}</td>
            </tr>
            </tbody>
        </table>
    </div>;
}