import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {fetchSecure} from "shared/net";
import {Spin} from "antd";

export interface ISearchResultItem {
    id: string
    title: string
    description: string
    relatedInfo: IRelatedInfo
}

export interface IRelatedInfo {
    account_id: string
    account_number: string
    unit: string
    address: string
}

export default function GlobalSearchResultsPage() {

    const {globalSearchQuery} = useSelector((state: any) => state.app);
    const [searchResults, setSearchResults] = useState<ISearchResultItem[] | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {

        if (globalSearchQuery) {
            fetchSecure(`/api/1/search?q=${globalSearchQuery}`)
                .then(response => response.json())
                .then(data => {
                    if (data.items.length === 1) {
                        const result = data.items[0];
                        navigate(`/support/meters/${result.id}`);
                    } else {
                        setSearchResults(data.items);
                    }
                });
        }

        return () => {
            // Cleanup
        }
    }, [globalSearchQuery, navigate]);

    const navigateToSearchResult = (result: ISearchResultItem) => {
        navigate(`/support/meters/${result.id}`);
    }

    return <div>
        <h2>Showing results for "...{globalSearchQuery}..."</h2>
        <div className={"search-results"}>
            {searchResults === undefined && <div style={{textAlign: 'center', margin: 50}}><Spin size="large"/></div>}
            {searchResults && searchResults?.length === 0 && <div>No results found</div>}
            {searchResults && searchResults?.length === 1 && <div>Redirecting...</div>}
            {searchResults && searchResults?.length > 1 && searchResults?.map((result, index) => (
                <div key={index} className={"search-result-item"} onClick={() => navigateToSearchResult(result)}>
                    <div className={"title"}>Meter {result.title}</div>
                    <p>{result.description}</p>
                    <p>
                        Installed
                        against: <b>{result.relatedInfo.account_number || 'No current connection point'}</b> <br/>
                        Address: {result.relatedInfo.unit ?
                        <b>{result.relatedInfo.unit} - {result.relatedInfo.address}</b> :
                        <b>No address available</b>
                    }
                    </p>
                </div>
            ))}
        </div>
    </div>
}