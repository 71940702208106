
// create a fetch wrapper that checked for a 401 response and redirects to the login page
// this is a common pattern for SPAs
export async function fetchSecure(url: string, options?: RequestInit): Promise<Response> {
    const response = await fetch(url, options);
    if (response.status === 401) {
        window.location.reload();
    }
    return response;
}
