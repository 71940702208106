import {IOverviewHistoryItemPropType} from "./overview-history-types";
import {getEventDisplayName} from "../support-helpers";
import Token from "../../../../components/Token";

interface IEngTokenHistoryItemData {
    is_error: boolean
    message: string
    meter_id: string
    meter_number: string
    token: string
    token_type: string
}


export default function EngTokenHistoryItem({entry}: IOverviewHistoryItemPropType) {
    const item: IEngTokenHistoryItemData = entry.eventData;

    return <div>
        <table>
            <tbody>
            <tr>
                <th>{getEventDisplayName(item.token_type)}:</th>
                <th>Token</th>
                <td><Token token={{
                    token: item.token,
                    type: item.token_type,
                    units: '0',
                }}/></td>
            </tr>
            </tbody>
        </table>
    </div>;
}