import {formatCurrency, toDisplayDate} from "../../../shared/utils";
import {Button} from "antd";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {fetchSecure} from "shared/net";
import {EyeOutlined} from "@ant-design/icons";

interface VendTransaction {
    id: number
    dateCreated: string
    meterID: string
    meterNumber: string
    accountID: string
    accountNumber: string
    billingEntityID: string
    billingEntityName: string
    address: string
    unit: string
    tenderedAmount: number
    product: string
    unitsGenerated: number
    productUnitAbbr: string
    collections: any
}

export default function VendListPage() {

    const [vendTransactions, setVendTransactions] = useState<VendTransaction[]>([]);

    useEffect(() => {
        const fetchVendTransactions = async () => {
            const response = await fetchSecure('/api/1/vending/transactions')
            const data = (await response.json())
            setVendTransactions(data)
        }
        fetchVendTransactions().catch(console.error);
    }, []);

    return <>
        <h1>Recent Failed Transactions</h1>
        <p><i>No recent failures</i></p>
        <h1>Recent Transactions</h1>
        <table className="bordered shadow data full-width">
            <thead>
            <tr>
                <th style={{width: 80}}>ID</th>
                <th style={{width: 180}}>Date</th>
                <th style={{width: 70}}>Meter</th>
                <th style={{width: 70}}>Unit</th>
                <th>Address</th>
                <th style={{width: 100}}>Amount</th>
                <th style={{width: 50}}>Units</th>
                <th style={{width: 100}}></th>
            </tr>
            </thead>
            <tbody>
            {vendTransactions.map((vend, index) => {
                return <tr key={index}>
                    <td>{vend.id}</td>
                    <td>{toDisplayDate(vend.dateCreated, false)}</td>
                    <td>
                        <Link to={`/support/meters/${vend.meterID}`}>
                            <Button type="link" size="small">{vend.meterNumber}</Button>
                        </Link>
                    </td>
                    <td style={{textAlign: "right"}}>{vend.unit}</td>
                    <td>{vend.address}</td>
                    <td>{formatCurrency(vend.tenderedAmount)}</td>
                    <td>{vend.unitsGenerated}{vend.productUnitAbbr}</td>
                    <td>
                        <a href={`https://ppex.us/receipt?vend-id=${vend.id}`} target="_blank" rel="noreferrer"
                           title="Sneak Peek"><EyeOutlined/></a>
                        <Link to={`/vending/transactions/${vend.id}`}>
                            <Button size="small" type="link">View</Button>
                        </Link>
                    </td>
                </tr>
            })}
            </tbody>
        </table>
    </>
}