import {
    AutoComplete,
    AutoCompleteProps,
    Card,
    Flex,
} from "antd";
import { useState } from "react";
import { debounce } from "shared/utils";
// import MeterSelector from "components/meter-selector";

// ant-select-option-item-active

const MeterLabel = ({ meter }: { meter: string }) => (
    <div className="auto-complete-item123">
        <div>
            <span
                style={{
                    display: "inline-block",
                    width: 120,
                    fontWeight: "bold",
                }}
            >
                Meter Nr.:
            </span>
            <span>{meter}</span>
        </div>
        <div>
            <span
                style={{
                    display: "inline-block",
                    width: 120,
                    fontWeight: "bold",
                }}
            >
                Account Nr.:
            </span>
            <span>RC931-31</span>
        </div>
        <div>
            <span
                style={{
                    display: "inline-block",
                    width: 120,
                    fontWeight: "bold",
                }}
            >
                Address:
            </span>
            <span>18 Main Rd</span>
        </div>
    </div>
);

export default function CreateCollectionPage() {
    const [opts, setOptions] = useState<AutoCompleteProps["options"]>([]);

    const onSearch = debounce((searchText: string) => {
        setOptions(
            !searchText
                ? []
                : [
                      {
                          label: <MeterLabel meter={searchText} />,
                          value: searchText,
                      },
                      {
                          label: <MeterLabel meter={searchText + "1"} />,
                          value: searchText + "1",
                      },
                      // { label: searchText + "-3", value: searchText },
                  ]
        );
    }, 500);

    const onSelect = (data: string) => {
        console.log("onSelect", data);
    };

    return (
        <>
            <h1>Create Collection</h1>
            <Flex justify="space-between" gap={4}>
                <div style={{ flex: 1 }}>
                    <Card style={{ width: 400 }} hoverable>
                        {/* <MeterSelector /> */}

                        <AutoComplete
                            allowClear
                            options={opts}
                            style={{ width: "100%" }}
                            onSelect={onSelect}
                            onSearch={onSearch}
                        />

                        {/* <div style={{ margin: 4, paddingTop: 10 }}>
                            <Flex justify="space-between" gap={4}>
                                <div
                                    style={{
                                        borderRight: "1px solid #ddd",
                                        borderLeft: "1px solid #ddd",
                                        flex: 1,
                                    }}
                                    className="meter-select-list"
                                >
                                    <div>
                                        <Button type="link">20031516867</Button>
                                        <p>AP9918 - 18 Main Rd</p>
                                    </div>
                                    <Divider />
                                    <div>
                                        <Button type="link">1981920323</Button>
                                        <p>SAP934 - 24 Kastanje Str</p>
                                    </div>
                                    <Divider />
                                    <div className="selected">
                                        <Button type="link">2002919893</Button>
                                        <p>ARS0319 - 19 Arsenal Ave</p>
                                    </div>
                                    <Divider />
                                    <div
                                        style={{
                                            marginTop: 10,
                                            color: "gray",
                                            fontStyle: "italic",
                                        }}
                                    >
                                        There are more meters... Please refine
                                        your search.
                                    </div>
                                </div>
                            </Flex>
                        </div> */}
                    </Card>
                </div>
                <div style={{ flex: 1 }}>
                    <h2>Selected Meter</h2>
                </div>
            </Flex>
        </>
    );
}
