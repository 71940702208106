import { createSlice } from '@reduxjs/toolkit'

const initialSettings = (window as any).ppexSettings;

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        authenticated: !!initialSettings,
        name: initialSettings?.name,
        email: initialSettings?.email,
        roles: initialSettings?.roles,
    },
    reducers: {
        setUser: (state, user: any) => {
            state.authenticated = user.payload.authenticated
            state.name = user.payload.name
            state.email = user.payload.email
            state.roles = user.payload.roles
        },
    },
})

// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions

export default userSlice.reducer