import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {fetchSecure} from "../../../shared/net";
import {toShortDate} from "../../../shared/utils";
import {Button, Card, Col, Form, Input, message, Row} from "antd";

interface FieldType {
    unitNumber: string
    waterMeterNumber: string
    electricityMeterNumber: string
}

interface TakeOnResponse {
    id: string
    billingGroupID: string
    billingGroup: string
    billingEntityID: string
    billingEntity: string
    name: string
    description: string
    startDate: string
    dueDate: string
    dateCompleted: any
    defaultSettings: DefaultSettings
}

interface DefaultSettings {
    water: Water
    electricity: Electricity
}

interface Water {
    fromTI: number
    fromKRN: number
    fromSGC: number
    productId: string
    chargeGroupId: string
}

interface Electricity {
    fromTI: number
    fromKRN: number
    fromSGC: number
    productId: string
    chargeGroupId: string
}

interface TakeOnMeter {
    id: number
    meterNumber: string
    unit: string
    kct1: string
    kct2: string
    submittedToVendorRouting: boolean
}


export default function TakeonAddUnitPage() {

    const {id} = useParams();
    const [api, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const [takeOn, setTakeOn] = useState<TakeOnResponse>();
    const [metersSubmitted, setMetersSubmitted] = useState<TakeOnMeter[]>([]);
    const [metersNotSubmitted, setMetersNotSubmitted] = useState<TakeOnMeter[]>([]);

    function loadMeters() {
        fetchSecure(`/api/1/take-ons/${id}/meters`).then(response => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Failed to fetch');
        }).then((data: TakeOnMeter[]) => {
            setMetersNotSubmitted(data.filter(i => !i.submittedToVendorRouting));
            setMetersSubmitted(data.filter(i => i.submittedToVendorRouting));
        }).catch(() => {
            return api.open({
                type: 'error',
                content: 'Failed to fetch meters',
            });
        })
    }

    useEffect(() => {
        fetchSecure(`/api/1/take-ons/${id}`).then(response => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Failed to fetch');
        }).then((data: TakeOnResponse) => {
            setTakeOn(data);
        }).catch(() => {
            return api.open({
                type: 'error',
                content: 'Failed to fetch take on details',
            });
        });

        loadMeters();

    }, []);

    async function onFinish(values: FieldType) {
        const response = await fetchSecure(`/api/1/take-ons/${id}/units`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                unitNumber: values.unitNumber,
                waterMeterNumber: values.waterMeterNumber,
                electricityMeterNumber: values.electricityMeterNumber,
            }),
        });

        if (response.ok) {
            api.open({
                type: 'success',
                content: 'Unit created',
            });
            form.resetFields();
            loadMeters();
        } else {
            api.open({
                type: 'error',
                content: 'Failed to create the unit',
            });
        }
    }


    return <>
        {contextHolder}
        <h1>Take On - Add Unit</h1>
        <Row>
            <Col span={10}>
                {takeOn && <div>
                    <table className="bordered data">
                        <thead>
                        <tr>
                            <th>Take On Name</th>
                            <th>Description</th>
                            <th>Start Date</th>
                            <th>Due Date</th>
                            <th>Date Completed</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{takeOn.name}</td>
                            <td>{takeOn.description}</td>
                            <td>{toShortDate(takeOn.startDate)}</td>
                            <td>{toShortDate(takeOn.dueDate)}</td>
                            <td>{takeOn.dateCompleted}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>}

                <div style={{width: 400, marginTop: 20}}>
                    <Card bordered title="Capture new unit">

                        <Form
                            name="newNotice"
                            autoComplete="off"
                            onFinish={onFinish}
                            layout="vertical"
                            form={form}
                        >
                            <Form.Item<FieldType> name="unitNumber" label="Unit Number">
                                <Input style={{width: 100}}/>
                            </Form.Item>

                            <Form.Item<FieldType> name="waterMeterNumber" label="Water Meter Number">
                                <Input style={{width: 200}}/>
                            </Form.Item>


                            <Form.Item<FieldType> name="electricityMeterNumber" label="Electricity Meter Number">
                                <Input style={{width: 200}}/>
                            </Form.Item>

                            <div style={{textAlign: "right"}}>
                                <Button type="primary" htmlType="submit">Save</Button>
                            </div>

                        </Form>
                    </Card>
                </div>
            </Col>
            <Col span={14}>
                <h2>Meters already captured</h2>
                <h3>Meters NOT submitted to
                    vendor {metersNotSubmitted.length > 0 && <>({metersNotSubmitted.length})</>}</h3>
                {metersNotSubmitted.length === 0 && <p>No meters captured that are not submitted</p>}
                {metersNotSubmitted.length > 0 && <table className="bordered data full-width">
                    <thead>
                    <tr>
                        <th>Meter Number</th>
                        <th>Unit</th>
                        <th>KCT1</th>
                        <th>KCT2</th>
                    </tr>
                    </thead>
                    <tbody>
                    {metersNotSubmitted.filter(i => !i.submittedToVendorRouting).map((meter, index) => (
                        <tr key={index}>
                            <td>{meter.meterNumber}</td>
                            <td>{meter.unit}</td>
                            <td>{meter.kct1}</td>
                            <td>{meter.kct2}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>}
                <h3>Meters submitted to vendor {metersSubmitted.length > 0 && <>({metersSubmitted.length})</>}</h3>

                {metersSubmitted.length === 0 && <p>No meters captured that are not submitted</p>}
                {metersSubmitted.length > 0 && <table className="bordered data full-width">
                    <thead>
                    <tr>
                        <th>Meter Number</th>
                        <th>Unit</th>
                        <th>KCT1</th>
                        <th>KCT2</th>
                    </tr>
                    </thead>
                    <tbody>
                    {metersSubmitted.filter(i => i.submittedToVendorRouting).map((meter, index) => (
                        <tr key={index}>
                            <td>{meter.meterNumber}</td>
                            <td>{meter.unit}</td>
                            <td>{meter.kct1}</td>
                            <td>{meter.kct2}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>}
            </Col>
        </Row>
    </>
}