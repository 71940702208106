import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        globalSearchQuery: "",
    },
    reducers: {
        setSearchQuery: (state, data: any) => {
            state.globalSearchQuery = data.payload.globalSearchQuery
        },
    },
})

// Action creators are generated for each case reducer function
export const { setSearchQuery } = appSlice.actions

export default appSlice.reducer