import {IOverviewHistoryItemPropType} from "./overview-history-types";


interface KctRequestedHistoryItemData {
    account_id: string
    account_number: string
    from_krn: number
    from_sgc: number
    meter_id: string
    meter_number: string
    ready_for_generation: boolean
    subclass: number
    ti: number
    to_krn: number
    to_sgc: number
}


export default function KctRequestedHistoryItem({entry}: IOverviewHistoryItemPropType) {
    const item: KctRequestedHistoryItemData = entry.eventData;

    return <div>
        <table>
            <tbody>
            <tr>
                <th>KCT Request Created:</th>
                <th>Account</th>
                <td>{item.account_number}</td>
                <th>From</th>
                <td>SGC={item.from_sgc}, KRN={item.from_krn}</td>
                <th>To</th>
                <td>SGC={item.to_sgc}, KRN={item.to_sgc}</td>
                <th>Ready for Generation</th>
                <td>{item.ready_for_generation ? 'Yes' : 'No'}</td>
            </tr>
            </tbody>
        </table>
    </div>;
}