import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Input, message, Popover, Spin } from "antd";
import { useState } from "react";
import { fetchSecure } from "shared/net";

export interface IProperty {
    id: string;
    type: string;
    name: string;
    field: string;
    entity: string;
    value: any;
}

interface IEditableValueProps {
    prop: IProperty;
    displayFormatter?: (value: any) => string;
    onSaved?: () => void;
}

export default function EditableValue({ prop, displayFormatter }: IEditableValueProps) {

    const [editedValue, setEditedValue] = useState<string>(prop.value);
    const [saving, setSaving] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [api, contextHolder] = message.useMessage();

    function save() {
        setSaving(true);
        fetchSecure('/api/1/in-place-update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: prop.id,
                type: prop.type,
                entity: prop.entity,
                field: prop.field,
                value: editedValue
            })
        }).then(() => {
            prop.value = displayFormatter ? displayFormatter(editedValue) : editedValue;
            setSaving(false);
            setIsOpen(false);
            api.success({
                content: prop.name + ' saved',
            });
        }).catch(() => {
            setEditedValue(prop.value);
            setSaving(false);
            setIsOpen(false);
            api.error({
                content: 'Failed to save ' + prop.name,
            });
        });
    }

    function cancel() {
        setEditedValue(prop.value);
        setIsOpen(false);
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            save();
        } else if (e.key === 'Escape') {
            cancel();
        }
    }

    function getContent() {
        return <>
            {contextHolder}
            <Input value={editedValue}
                onKeyDown={handleKeyDown}
                onChange={(e) => setEditedValue(e.target.value)}
            />
            <div style={{ marginTop: 4, textAlign: "right" }}>
                {saving && <Spin size="small" style={{ backgroundColor: 'white' }} />}
                <Button type="primary" style={{ marginLeft: 10 }} disabled={saving} onClick={save}>
                    <CheckOutlined />
                </Button>
                <Button style={{ marginLeft: 2 }} disabled={saving} onClick={cancel}>
                    <CloseOutlined />
                </Button>
            </div>
        </>
    }

    return <>
        <Popover content={getContent()} title={`Edit ${prop.name}`} trigger="click" open={isOpen}>
            <span style={{ cursor: "pointer" }} title="Click to edit" onClick={() => setIsOpen(!isOpen)}>{prop.value}</span>
        </Popover>
    </>;
}