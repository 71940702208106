import {useEffect, useState} from "react";
import {toDisplayDate, toShortDate} from "../../../shared/utils";
import {Button} from "antd";
import {fetchSecure} from "shared/net";
import {Link} from "react-router-dom";

interface TakeOn {
    id: string;
    name: string,
    description: string,
    startDate: string,
    dueDate: string,
    dateCompleted: string
}

export default function TakeOnOverviewPage() {

    const [takeOns, setTakeOns] = useState<TakeOn[]>([]);


    useEffect(() => {
        const fetchTakeOns = async () => {
            const response = await fetchSecure('/api/1/take-ons')
            const data = (await response.json())
            setTakeOns(data)
        }
        fetchTakeOns().catch(console.error);
    }, []);

    return <>
        <h1>Take On</h1>
        <p>Here you can see all the take ons</p>
        <table className="bordered shadow data full-width">
            <thead>
            <tr>
                <th style={{width: 250}}>Name</th>
                <th>Description</th>
                <th style={{width: 100}}>Start Date</th>
                {/*<th colSpan={3}>Status</th>*/}
                <th style={{width: 100}}>Due Date</th>
                <th style={{width: 100}}>Date Ended</th>
                <th style={{width: 50}}></th>
            </tr>
            </thead>
            <tbody>
            {takeOns.map((takeOn, index) => {
                return <tr key={index}>
                    <td>{takeOn.name}</td>
                    <td>{takeOn.description}</td>
                    <td>{toShortDate(takeOn.startDate)}</td>
                    {/*<td title="No vends"*/}
                    {/*    style={{ width: 40, backgroundColor: '#c00', color: 'white', textAlign: 'center' }}>35*/}
                    {/*</td>*/}
                    {/*<td title="1 vend only" style={{ width: 40, backgroundColor: '#fA6', textAlign: 'center' }}>36</td>*/}
                    {/*<td title="More than 1 vend" style={{ width: 40, backgroundColor: '#6C6', textAlign: 'center' }}>75*/}
                    {/*</td>*/}
                    <td>{toShortDate(takeOn.dueDate)}</td>
                    <td>{takeOn.dateCompleted}</td>
                    <td>
                        <Link to={`/take-on/${takeOn.id}`}>
                            <Button type="link">View</Button>
                        </Link>
                    </td>
                </tr>
            })}
            </tbody>
        </table>
    </>
}