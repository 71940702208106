import { fetchSecure } from "shared/net";

export interface IEngMeterInfo {
    number: string;
    subclass: string;
    sgc: string;
    krn: string;
    ti: string;
    ea: string;
    tct: string;
}

export interface IEngineeringTokenRequest {
    meter: IEngMeterInfo;
    tokenType: string;
    kctInfo?: {
        sgc: string;
        krn: string;
        ti: string;
    };
}

interface ITokenResponse {
    tokens: {
        token: string;
        description: string;
    }[];
}

export async function generateEngToken(meter: IEngMeterInfo, type: string): Promise<string> {

    const res = await fetchSecure("/api/1/engineering/generate", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            meter: {
                subclass: meter.subclass,
                number: meter.number,
                sgc: meter.sgc,
                krn: meter.krn,
                ti: meter.ti,
                ea: meter.ea,
                tct: meter.tct,
            },
            tokenType: type,
        }),
    })

    if (!res.ok) {
        return "Error generating token"
    }
    const data: ITokenResponse = await res.json()
    return data.tokens[0].token
}