import {Col, Radio, Row, DatePicker, Select, Button} from "antd";
import {useEffect, useState} from "react";
import {fetchSecure} from "../../../shared/net";

const {RangePicker} = DatePicker;

export default function BESalesReportPage() {

    const [billingEntities, setBillingEntities] = useState([]);
    const [billingEntity, setBillingEntity] = useState("");
    const [productType, setProductType] = useState(1);
    const [dateRange, setDateRange] = useState<[string, string]>(["", ""]);
    const [queryData, setQueryData] = useState("");

    const [canGenerate, setCanGenerate] = useState(false);

    useEffect(() => {
        setCanGenerate(billingEntity !== "" && dateRange[0] !== "" && dateRange[1] !== "");
    }, [billingEntity, dateRange]);

    useEffect(() => {
        const fetchBillingEntities = async () => {
            const response = await fetchSecure('/api/1/list-items/billing_entities')
            const data = (await response.json())
            setBillingEntities(data)
        }
        fetchBillingEntities().catch(console.error);
    }, []);

    function onProductTypeChange(e: any) {
        setProductType(e.target.value);

        console.log('ProductType', e.target.value);
    }

    function onBESelect(item: any) {
        console.log('BE Selected', item);
        setBillingEntity(item);
    }

    function onDateRangeChange(dates: any, dateStrings: [string, string]) {
        setDateRange(dateStrings);
    }

    return (
        <div>
            <h1>Billing Entity Sales Report</h1>
            <Row>
                <Col span={8}>
                    <Select style={{width: 300}}
                            placeholder="Billing Entity"
                            onSelect={onBESelect}
                            options={billingEntities}/>
                </Col>
                <Col span={4}>
                    <Radio.Group onChange={onProductTypeChange} value={productType} optionType="button"
                                 buttonStyle="solid">
                        <Radio value={1}>Electricity</Radio>
                        <Radio value={2}>Water</Radio>
                    </Radio.Group>
                </Col>
                <Col span={8}>
                    <RangePicker onChange={onDateRangeChange}/>
                </Col>
                <Col span={4}>
                    <Button type="primary" disabled={!canGenerate}>Generate Report</Button>
                </Col>
            </Row>

            {queryData && queryData !== "" && <div style={{marginTop: 15}}>
                <iframe src={`/api/1/reporting/reports/be-sales-report?req=` + queryData} width="100%" height="800px"/>
            </div>}
        </div>
    )
}

