import {IOverviewHistoryItemPropType} from "./overview-history-types";
import Token from "components/Token";
import {toDisplayDate} from "shared/utils";
import {useState} from "react";
import {Button} from "antd";

interface KctGeneratedHistoryItemData {
    account_id: string
    account_number: string
    from_krn: number
    from_sgc: number
    generated: boolean
    generated_date: string
    meter_id: number
    meter_number: string
    ready_for_generation: boolean
    subclass: number
    ti: number
    to_krn: number
    to_sgc: number
    token1: string
    token2: string
}

export default function KctGeneratedHistoryItem({entry}: IOverviewHistoryItemPropType) {
    const [expanded, setExpanded] = useState(false);

    const item: KctGeneratedHistoryItemData = entry.eventData;

    return <div>
        <table>
            <tbody>
            <tr>
                <td><Button style={{width: 30}} size="small"
                            onClick={() => setExpanded(!expanded)}>{expanded ? '-' : '+'}</Button></td>
                <th>KCT Generated:</th>
                <th>Account</th>
                <td>{item.account_number}</td>
                <th>From</th>
                <td>SGC={item.from_sgc}, KRN={item.from_krn}</td>
                <th>To</th>
                <td>SGC={item.to_sgc}, KRN={item.to_sgc}</td>
            </tr>
            </tbody>
        </table>
        {!expanded ? null : <div>
            <div>
                <Token token={{
                    token: item.token1,
                    type: '1. KCT',
                    units: '0',
                }}/></div>
            <div>
                <Token token={{
                    token: item.token2,
                    type: '2. KCT',
                    units: '0',
                }}/>
            </div>
        </div>
        }
    </div>
}
