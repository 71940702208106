import {Button, Col, Form, Input, Row, Select} from "antd";


export default function NewIncident() {

    function onFinish(values: any) {
        console.log(values)
    }

    return <>
        <h3 style={{marginTop: 5, marginBottom: 2, padding: 0}}>New incident</h3>
        <Form
            name="newIncident"
            autoComplete="off"
            onFinish={onFinish}
            layout="vertical"
        >
            <Row gutter={12}>
                <Col span={6}>
                    <Form.Item label="Type">
                        <Select defaultValue={"1"}>
                            <Select.Option value="1">Token not accepted</Select.Option>
                            <Select.Option value="2">KCT not working</Select.Option>
                            <Select.Option value="3">Battery Low</Select.Option>
                            <Select.Option value="3">Meter Fault</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Summary" required>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Extra Detail">
                        <Input.TextArea rows={5}/>
                    </Form.Item>

                </Col>
                <Col span={6}>
                    <Form.Item label="Contact Name" required>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Contact Number" required>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Contact Email">
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Contact Capacity" required>
                        <Select defaultValue={"1"}>
                            <Select.Option value="1">Occupant</Select.Option>
                            <Select.Option value="2">Owner</Select.Option>
                            <Select.Option value="3">??</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item label="Channel">
                        <Select defaultValue={"1"}>
                            <Select.Option value="1">WhatsApp Message</Select.Option>
                            <Select.Option value="2">WhatsApp Call</Select.Option>
                            <Select.Option value="3">Call</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Status">
                        <Select defaultValue={"1"}>
                            <Select.Option value="1">New</Select.Option>
                            <Select.Option value="2">In Progress</Select.Option>
                            <Select.Option value="3">Awaiting Client Feedback</Select.Option>
                            <Select.Option value="4">Client Communication Required</Select.Option>
                            <Select.Option value="5">Closed</Select.Option>
                            <Select.Option value="6">Cancelled</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="Priority">
                        <Select>
                            <Select.Option value="1">Highest</Select.Option>
                            <Select.Option value="2">Medium</Select.Option>
                            <Select.Option value="3">Low</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Assigned To">
                        <Select>
                            <Select.Option value="1">Cormia Esterhuizen</Select.Option>
                            <Select.Option value="2">Jana Esterhuizen</Select.Option>
                            <Select.Option value="3">Monique Crafford</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item label="Resolution">
                        <Input.TextArea rows={9}/>
                    </Form.Item>
                    <Form.Item label="External Ticket">
                        <Input/>
                    </Form.Item>
                </Col>
            </Row>
            <div style={{textAlign: 'right'}}>
                <Button type="primary" htmlType="submit">Save</Button>&nbsp;
                <Button type="primary" danger>Cancel</Button>
            </div>

        </Form>
    </>
}