export function getEventDisplayName(event: string): string {
    switch (event) {
        case 'meters.created':
            return 'Initial DB insert of the meter';
        default:
            return getDefaultEventName(event);
    }
}

function getDefaultEventName(name: string): string {
    // replace all "." with " " and capitalize the first letter of each word
    return name.replace(/\./g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
}