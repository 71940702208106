import {IOverviewHistoryItemPropType} from "./overview-history-types";
import Token from "../../../../components/Token";
import {useState} from "react";
import {Button} from "antd";

interface ICallLogHistoryItemData {
    Contact_1: string
    Contact_2: any
    bc: string
    call_date: string
    call_time: string
    caller_capacity: any
    description: string
    email: any
    log_date: string
    meter_id: string
    meter_number: string
    name: any
    resolution: string
    ticket_number: any
    unit: string
    via: string
}

export default function CallLogHistoryItem({entry}: IOverviewHistoryItemPropType) {

    const [expanded, setExpanded] = useState(false);
    const item: ICallLogHistoryItemData = entry.eventData;

    return (
        <div>
            <table>
                <tbody>
                <tr>
                    <td><Button style={{width: 30}} size="small"
                                onClick={() => setExpanded(!expanded)}>{expanded ? '-' : '+'}</Button></td>
                    <th>SUPPORT CALL:</th>
                    <td>{item.description}</td>
                </tr>
                </tbody>
            </table>
            {expanded && <div className="history-item-detail">
                <table className="bordered white full-width">
                    <tbody>
                    <tr>
                        <th>Resolution:</th>
                        <td colSpan={3}>{item.resolution}</td>
                    </tr>
                    <tr>
                        <th>Caller Capacity:</th>
                        <td>{item.caller_capacity}</td>
                        <th>Via:</th>
                        <td>{item.via}</td>
                    </tr>
                    <tr>
                        <th>Contact 1:</th>
                        <td>{item.Contact_1}</td>
                        <th>Contact 2:</th>
                        <td>{item.Contact_2}</td>
                    </tr>
                    <tr>
                        <th>Name:</th>
                        <td>{item.name}</td>
                        <th>Email:</th>
                        <td>{item.email}</td>
                    </tr>
                    <tr>
                        <th>Ticket Number:</th>
                        <td colSpan={3}>{item.ticket_number}</td>
                    </tr>
                    </tbody>
                </table>
            </div>}
        </div>
    );
}
