import {useEffect, useState} from "react";

type RoleList = string[] | undefined;

interface RoleDefinition {
    roleName: string;
    isAllowed: boolean;
}

export function hasRole(
    userRoles: RoleDefinition[],
    requiredRoles: RoleList
): boolean {
    if (!requiredRoles || requiredRoles.length === 0) {
        return true;
    }

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    const isSuperAdmin = userRoles.some(
        (role) => role.roleName === "Super Admin" && role.isAllowed
    );
    if (isSuperAdmin) {
        return true;
    }

    const disallowedRoles = userRoles
        .filter((role) => !role.isAllowed)
        .map((role) => role.roleName);

    // If any of the allowed roles are in the disallowed roles list, return false
    if (requiredRoles.some((role) => disallowedRoles.includes(role))) {
        return false;
    }

    const allowedRoles = userRoles
        .filter((role) => role.isAllowed)
        .map((role) => role.roleName);
    return requiredRoles.some((role) => allowedRoles.includes(role));
}

export function useDebounce(cb: any, delay: number) {
    const [debounceValue, setDebounceValue] = useState(cb);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceValue(cb);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [cb, delay]);
    return debounceValue;
}

export function debounce<T>(fn: (a: T) => void, delay: number): (a: T) => void {
    let handler: NodeJS.Timeout | undefined;
    return (a: T) => {
        if (handler) clearTimeout(handler);

        handler = setTimeout(() => {
            fn(a);
            handler = undefined;
        }, delay);
    };
}

export function timeAgo(date: Date): string {
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " yrs ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " mths ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hrs ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " min ago";
    }
    return Math.floor(seconds) + " sec ago";
}

export function toShortDate(date: string): string {
    /*
    from: 2024-09-18T00:06:51.283287+01:00
    to: 18/09/2024
     */
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString("en-GB");
}

export function toDisplayDate(date: string, includeAgo: boolean = true): string {
    /*
    from: 2024-09-18T00:06:51.283287+01:00
    to: 18/09/2024 00:06:51
    and the time should be in 24-hour format local time
     */
    const dateObj = new Date(date);

    // rather make the date format  DD MMM YYYY HH:MM:SS
    const options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    };
    // @ts-ignore
    return dateObj.toLocaleString("en-GB", options) + (includeAgo ? ` (${timeAgo(dateObj)})` : "");
}

export function formatCurrency(amount: number): string {
    return amount.toFixed(2)
}

export function formatCents(amount: number): string {
    return formatCurrency(amount / 100);
}