import {IOverviewHistoryItemPropType} from "./overview-history-types";
import {formatCurrency} from "shared/utils";

interface CollectionsCreatedHistoryItemData {
    collection_id: number
    account_id: string
    account_number: string
    amount: number
    meter_number: string
    reason: string
}


export default function CollectionsCreatedHistoryItem({entry}: IOverviewHistoryItemPropType) {
    const item: CollectionsCreatedHistoryItemData = entry.eventData;

    return <div>
        <table>
            <tbody>
            <tr>
                <th>Collection Created:</th>
                <th>Account</th>
                <td>{item.account_number}</td>
                <th>Reason</th>
                <td>{item.reason}</td>
                <th>Amount</th>
                <td>{formatCurrency(item.amount)}</td>
            </tr>
            </tbody>
        </table>
    </div>;
}