import {Button, Card, Col, Form, Radio, RadioChangeEvent, Row} from "antd";
import MeterDetails from "./MeterDetails";
import {useState} from "react";
import KctOptions from "./KctOptions";
import {IKctOptions, IMeterInfo, ITokenTypeInfo} from "./TokenGenInfo";

export default function EngineeringTokensPage() {
    const [tokenInfo, setTokenInfo] = useState<ITokenTypeInfo>({
        meter: {
            subclass: "",
            number: "",
            sgc: "999941",
            krn: "2",
            ti: "1",
            ea: "7",
            tct: "2",
        },
        tokenType: "",
        kctInfo: {
            sgc: "400161",
            krn: "2",
            ti: "1",
        },
    });

    const onTokenTypeChange = (e: RadioChangeEvent) => {
        setTokenInfo({...tokenInfo, tokenType: e.target.value});
    };

    const onMeterInfoChange = (info: IMeterInfo) => {
        setTokenInfo({...tokenInfo, meter: info});
    };

    const onKctOptionsChange = (options: IKctOptions) => {
        setTokenInfo({
            ...tokenInfo,
            kctInfo: options,
        });
    };

    return (
        <>
            <h1>Engineering Tokens</h1>
            <Form layout="vertical" initialValues={tokenInfo}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Card
                            title="1. Current Meter Details"
                            style={{
                                boxShadow: "0px 0px 15px #999",
                                marginBottom: 16,
                            }}
                        >
                            <MeterDetails
                                info={tokenInfo.meter}
                                onChange={onMeterInfoChange}
                            />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            title="2. Token Details"
                            style={{
                                boxShadow: "0px 0px 15px #999",
                                marginBottom: 16,
                            }}
                        >
                            <Form.Item label="Token Type">
                                <Radio.Group
                                    buttonStyle="solid"
                                    onChange={onTokenTypeChange}
                                >
                                    <Radio.Button value="test">
                                        Test
                                    </Radio.Button>
                                    <Radio.Button value="kct">
                                        Key Change
                                    </Radio.Button>
                                    <Radio.Button value="tamper">
                                        Clear Tamper
                                    </Radio.Button>
                                    <Radio.Button value="credit">
                                        Clear Credit
                                    </Radio.Button>
                                </Radio.Group>
                            </Form.Item>

                            {tokenInfo.tokenType === "kct" && (
                                <KctOptions
                                    options={tokenInfo.kctInfo}
                                    onChange={onKctOptionsChange}
                                />
                            )}

                            {tokenInfo.tokenType && (
                                <Button type="primary">Generate Token</Button>
                            )}
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            title="3. Result"
                            style={{
                                boxShadow: "0px 0px 15px #999",
                                marginBottom: 16,
                            }}
                        >
                            <div>Test Token</div>
                            <pre>5461-8646-4886-1233-2364</pre>
                            {/* <pre>{JSON.stringify(tokenInfo, null, 2)}</pre> */}
                        </Card>
                    </Col>
                </Row>
            </Form>
        </>
    );
}
