import React from "react";
import ReactDOM from "react-dom/client";
import App from "./pages/authed/App";
import reportWebVitals from "./reportWebVitals";
import {Provider} from "react-redux";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import store from "./stores/app-store";
import "./index.css";
import HomePage from "./pages/authed/HomePage";
import LoginPage from "./pages/not-authed/login";
import {ConfigProvider} from "antd";
import SettingsPage from "pages/authed/settings";
import NotFoundPage from "pages/shared/not-found";
import ReportsPage from "pages/authed/ReportsPage";
import ProfileOverviewPage from "pages/authed/reports/ProfileOverview";
import AccountMaintenancePage from "pages/authed/AccountManagement";
import CreateCollectionPage from "pages/authed/management/collections/CreateCollection";
import EngineeringTokensPage from "pages/authed/management/tokens/EngineeringTokens";
import GlobalSearchResultsPage from "./pages/authed/GlobalSearchResultsPage";
import MeterOverviewPage from "./pages/authed/support/MeterOverviewPage";
import TakeOnOverviewPage from "./pages/authed/takeon/TakeonOverview";
import VendListPage from "./pages/authed/vending/VendTransactions";
import InlineEditTestPage from "pages/authed/playground/line-edit-playground";
import IncidentHomePage from "./pages/authed/support/incidents/IncidentHomePage";
import FieldworkOverviewPage from "pages/authed/support/fieldwork/FieldworkOverview";
import PayoutPage from "./pages/authed/support/payouts/PayoutPage";
import TakeonAddUnitPage from "./pages/authed/takeon/TakeonAddUnit";
import BESalesReportPage from "./pages/authed/reports/BESalesReportPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                index: true,
                element: <HomePage/>,
            },
            {
                path: "/home",
                element: <HomePage/>,
            },
            {
                path: "/global-search-results",
                element: <GlobalSearchResultsPage/>,
            },
            {
                path: "/reports",
                children: [
                    {
                        index: true,
                        element: <ReportsPage/>,
                    },
                    {
                        path: "profile-overview",
                        element: <ProfileOverviewPage/>,
                    },
                    {
                        path: "be-sales-report",
                        element: <BESalesReportPage/>,
                    },
                ],
            },
            {
                path: "/support",
                children: [
                    {
                        index: true,
                        element: <IncidentHomePage/>,
                    },
                    {
                        path: "meters/:id",
                        element: <MeterOverviewPage/>,
                    },
                    {
                        path: "fieldwork",
                        element: <FieldworkOverviewPage/>,
                    },
                    {
                        path: "payouts",
                        element: <PayoutPage/>,
                    },
                ],
            },
            {
                path: "/account-management/",
                children: [
                    {
                        index: true,
                        element: <AccountMaintenancePage/>,
                    },
                    {
                        path: "collections",
                        children: [
                            {
                                path: "create",
                                element: <CreateCollectionPage/>,
                            },
                        ],
                    },
                    {
                        path: "engineering",
                        children: [
                            {
                                path: "generate-tokens",
                                element: <EngineeringTokensPage/>,
                            },
                        ],
                    },
                ],
            },
            {
                path: "/vending/transactions/",
                children: [
                    {
                        index: true,
                        element: <VendListPage/>,
                    },
                ],
            },
            {
                path: "/take-on/",
                children: [
                    {
                        index: true,
                        element: <TakeOnOverviewPage/>,
                    },
                    {
                        path: ":id",
                        element: <TakeonAddUnitPage/>,
                    },
                ],
            },
            {
                path: "/settings",
                element: <SettingsPage/>,
            },
            {
                path: "/playground",
                element: <InlineEditTestPage/>,
            },
        ],
        ErrorBoundary: NotFoundPage,
    },
    {
        path: "/login",
        element: <LoginPage/>,
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "#347564",
                        colorInfo: "#347564",
                        colorSuccess: "#7fd455",
                        colorWarning: "#e29c0f",
                        colorError: "#e76668",
                        borderRadius: 6,
                    },
                    components: {
                        Layout: {
                            headerBg: "#347564",
                        },
                    },
                }}
            >
                <RouterProvider router={router}/>
            </ConfigProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
