import {CopyOutlined} from "@ant-design/icons";
import {notification} from "antd";

interface TokenPropType {
    token: IToken;
}

export interface IToken {
    token: string;
    type: string;
    units: string;
}

function getTokenText(token: IToken): string {
    if (+token.units > 0) {
        return `${token.type} - ${token.token} for ${token.units} units`;
    }
    return `${token.type} - ${token.token}`;
}

export default function Token({token}: TokenPropType) {
    const [api, contextHolder] = notification.useNotification();

    function copyToken() {
        const txt = getTokenText(token);
        navigator.clipboard.writeText(txt);
        api.success({
            message: 'Token copied to clipboard',
            description: txt,
        });
    }

    return <>
        {contextHolder}
        <span className="sts-token">
        {token.token}
            <CopyOutlined
                style={{marginLeft: 10}}
                title="Copy to clipboard"
                onClick={copyToken}/>
    </span>
    </>;
}