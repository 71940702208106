import {Button, Card, Dropdown, Form, Input, message, Select} from "antd";
import {ISupportMeterInfo} from "../overview-types";
import {fetchSecure} from "../../../../shared/net";

interface NoticesWidgetProps {
    info: ISupportMeterInfo;
    onInfoRefresh: () => void;
}

type FieldType = {
    message: string;
    level: string;
    type: string;
}

export default function NoticesWidget({info, onInfoRefresh}: NoticesWidgetProps) {

    const [api, contextHolder] = message.useMessage();
    const [form] = Form.useForm();

    const onFinish = async (values: FieldType) => {
        const response = await fetchSecure('/api/1/notices', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                meterID: info.meter.id,
                message: values.message,
                entityType: values.level,
                type: values.type,
            }),
        });

        if (response.ok) {
            api.open({
                type: 'success',
                content: 'Collection added',
            });
            form.resetFields();
            onInfoRefresh();
        } else {
            api.open({
                type: 'error',
                content: 'Failed to add collection',
            });
        }
    }

    function getEntityTypeName(et: string): string {
        switch (et) {
            case "accounts.meters":
                return "Meter";
            case "accounts.accounts":
                return "Account";
            case "accounts.billing_entities":
                return "Billing Entity";
            case "accounts.billing_group":
                return "Billing Group";
        }
        return "Unknown";
    }

    return <Card bordered={false} title="Notices" style={{marginTop: 5}}>
        {contextHolder}
        <table className="full-width bordered data">
            <thead>
            <tr>
                <th style={{width: 100}}>Level</th>
                <th>Message</th>
            </tr>
            </thead>
            <tbody>
            {info.entityNotices.map((notice, index) => (
                <tr key={index}
                    className={notice.type === 'critical' ? 'critical' : (notice.type === 'warning' ? 'warning' : 'info')}>
                    <td>{getEntityTypeName(notice.entityType)}</td>
                    <td>{notice.body}</td>
                </tr>
            ))}
            {info.entityNotices.length === 0 && <tr>
                <td colSpan={2}><i>No notices</i></td>
            </tr>}
            </tbody>
        </table>
        <h4 style={{marginBottom: 0}}>Add a notice</h4>
        <Form
            name="newNotice"
            autoComplete="off"
            onFinish={onFinish}
            form={form}
        >
            <table className="full-width quick-form">
                <tbody>
                <tr>
                    <td colSpan={3}>
                        <Form.Item<FieldType> name="message">
                            <Input placeholder="Message..."/>
                        </Form.Item>
                    </td>
                </tr>
                <tr>
                    <td>
                        <Form.Item<FieldType> name="level">
                            <Select placeholder="Entity Type" style={{width: 180}}>
                                <Select.Option value="accounts.meters">Meter</Select.Option>
                                <Select.Option value="accounts.accounts">Account</Select.Option>
                                <Select.Option value="accounts.billing_entities">Billing Entity</Select.Option>
                                <Select.Option value="accounts.billing_group">Billing Group</Select.Option>
                            </Select>
                        </Form.Item>
                    </td>
                    <td>
                        <Form.Item<FieldType> name="type">
                            <Select placeholder="Type" style={{width: '100%'}}>
                                <Select.Option value="critical">Critical</Select.Option>
                                <Select.Option value="warning">Warning</Select.Option>
                                <Select.Option value="info">Info</Select.Option>
                            </Select>
                        </Form.Item>
                    </td>
                    <td style={{width: 50}}><Button type="primary" htmlType="submit">Add</Button></td>
                </tr>
                </tbody>
            </table>
        </Form>
    </Card>
}