import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Input, Form, DatePicker, Popover, Spin } from "antd";
import { useState } from "react";

interface IProperty {
    name: string;
    field: string;
    entity: string;
    value: string;
}

interface IEditableValueProps {
    prop: IProperty;
    onSaved?: () => void;
}

function EditableValue({ prop }: IEditableValueProps) {

    const [editedValue, setEditedValue] = useState<string>(prop.value);
    const [saving, setSaving] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    function save() {
        setSaving(true);
        setTimeout(() => {
            setSaving(false);
            prop.value = editedValue;
            setIsOpen(false);
        }, 2000);
    }

    function cancel() {
        setEditedValue(prop.value);
        setIsOpen(false);
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            save();
        } else if (e.key === 'Escape') {
            cancel();
        }
    }

    function getContent() {
        return <>
            <Input value={editedValue}
                onKeyDown={handleKeyDown}
                onChange={(e) => setEditedValue(e.target.value)}
            />
            <div style={{ marginTop: 4, textAlign: "right" }}>
                {saving && <Spin size="small" style={{ backgroundColor: 'white' }} />}
                <Button type="primary" style={{ marginLeft: 10 }} disabled={saving} onClick={save}>
                    <CheckOutlined />
                </Button>
                <Button style={{ marginLeft: 2 }} disabled={saving} onClick={cancel}>
                    <CloseOutlined />
                </Button>
            </div>
        </>
    }

    return <>
        <Popover content={getContent()} title={`Edit ${prop.name}`} trigger="click" open={isOpen}>
            <span onClick={() => setIsOpen(!isOpen)}>{prop.value}</span>
        </Popover>
    </>;
}

export default function InlineEditTestPage() {

    const [prop, setProp] = useState<IProperty>({
        name: 'Unit',
        field: 'unit',
        entity: 'accounts.accounts',
        value: '1120',
    });

    return (
        <>
            <h1>Playground</h1>
            <h3>Inline editing test</h3>
            <div style={{ width: 200, marginLeft: 50 }}>
                <b>Unit:</b> <EditableValue prop={prop} />
            </div>
        </>
    );
}
