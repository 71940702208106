import {EventLogEntry} from "../overview-types";

interface IMeterAccountUpdatedHistoryItemPropType {
    entry: EventLogEntry;
}

interface MeterAccountUpdatedHistoryItemData {
    account_id: any
    account_number: any
    krn: string
    meter_id: string
    meter_number: string
    sgc: string
    subclass: string
    ti: string
}


export default function MeterAccountUpdatedHistoryItem({entry}: IMeterAccountUpdatedHistoryItemPropType) {

    const item: MeterAccountUpdatedHistoryItemData = entry.eventData;

    return <div>

        <table>
            <tbody>
            <tr>
                <th>Meter Account Updated:</th>
                {!item.account_id ?
                    <td>Account has been removed from meter. Meter is now not assigned</td> :
                    <>
                        <th>New Account</th>
                        <td>{item.account_number}</td>
                    </>
                }
            </tr>
            </tbody>
        </table>

    </div>;
}