import {Card} from "antd";

export default function CallLogHistoryWidget({entry}: { entry?: any }) {

    return <Card bordered={false} title="Recent Support Calls" style={{marginTop: 5}}>
        <table className="full-width bordered data">
            <thead>
            <tr>
                <th>Date</th>
                <th>Reason</th>
                <th>Outcome</th>
            </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </Card>
}