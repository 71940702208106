import { configureStore } from '@reduxjs/toolkit'
import { counterSlice } from "./counter-slice";
import { userSlice } from './user-slice';
import { appSlice } from "./app-slice";

export default configureStore({
    reducer: {
        app: appSlice.reducer,
        user: userSlice.reducer,
        counter: counterSlice.reducer,
    },
})