import {DatePicker} from "antd";
import {useEffect, useState} from "react";
import {fetchSecure} from "shared/net";
import {formatCurrency} from "shared/utils";

export default function PayoutPage() {

    const [date, setDate] = useState<any | null>(null);
    const [payouts, setPayouts] = useState<any[]>([]);

    useEffect(() => {
        if (!date) {
            return;
        }
        console.log(date.toString())
        fetchSecure('/api/1/financial/payouts?upTo=' + encodeURI(date.toString()))
            .then(response => response.json())
            .then(data => {
                setPayouts(data);
            });

    }, [date]);

    return <>
        <h1>Payouts</h1>
        <p>
            Up to: <DatePicker onChange={setDate}/>
        </p>
        <table className="bordered shadow data full-width">
            <thead>
            <tr>
                <th>Billing Group</th>
                <th>Billing Entity</th>
                <th>Amount</th>
            </tr>
            </thead>
            <tbody>
            {payouts.map((payout, index) => (
                <tr key={index}>
                    <td>{payout.billingGroup}</td>
                    <td>{payout.billingEntity}</td>
                    <td style={{textAlign: 'right'}}>{formatCurrency(+payout.amount)}</td>
                </tr>
            ))}
            <tr>
                <td colSpan={2} style={{textAlign: 'right'}}>Total:</td>
                <td style={{textAlign: 'right'}}>{formatCurrency(payouts.reduce((acc, curr) => acc + +curr.amount, 0))}</td>
            </tr>
            </tbody>
        </table>
    </>
}