import {IOverviewHistoryItemPropType} from "./overview-history-types";
import {formatCents} from "shared/utils";
import Token from "components/Token";
import {Button, notification} from "antd";
import {CopyOutlined} from "@ant-design/icons";
import {useState} from "react";

interface IVendResult {
    collections: Collection[]
    customer: string
    externalTransactionId: string
    footerInfo: string[]
    headerInfo: string[]
    meter: Meter
    productInfo: ProductInfo
    propertyInfo: PropertyInfo
    receiptInfo: ReceiptInfo
    receiptNumber: string
    tokens: IToken[]
    totalFeeCost: number
    totalFeeTax: number
    totalTendered: number
    utility: string
    vendDate: string
    vendId: string
    vendRecipient: VendRecipient
    vendResultId: number
}

interface Collection {
    amountPaid: number
    amountRemaining: number
    dateDue: string
    feesPaid: number
    feesTaxPaid: number
    reason: string
    totalAmountRequired: number
}

interface Meter {
    ea: string
    krn: string
    meterNumber: string
    sgc: string
    subclass: string
    tct: string
    ti: string
}

interface ProductInfo {
    cost: number
    feeCost: number
    feeTax: number
    name: string
    steps: Step[]
    tax: number
    token: string
    unitAbbr: string
    unitsVended: string
    unitsVendedToDateForPeriod: string
}

interface Step {
    cost: number
    max: number
    min: number
    rate: number
    units: string
}

interface PropertyInfo {
    address: string
    erfNumber: string
    unit: string
}

interface ReceiptInfo {
    collections: Collection2[]
    productType: string
    productUnit: string
    totalExcl: number
    totalIncl: number
    totalTax: number
}

interface Collection2 {
    amountIncl: number
    reason: string
}

interface IToken {
    token: string
    type: string
    units: string
}

interface VendRecipient {
    address: string
    name: string
    vatNumber: string
}

function getTokenText(token: IToken): string {
    if (+token.units > 0) {
        return `${token.type} - ${token.token} for ${token.units} units`;
    }
    return `${token.type} - ${token.token}`;
}


export default function VendResult({entry}: IOverviewHistoryItemPropType) {

    const [expanded, setExpanded] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    let vend: IVendResult = entry.eventData;

    if (!vend || Object.keys(vend).length === 0) {
        return <div>Invalid Vend</div>;
    }

    let totalCollected = !vend.collections ? 0 : vend.collections.reduce((acc, collection) => acc + collection.amountPaid, 0);

    const kctGenerated: boolean = !vend.tokens ? false : vend.tokens.some(token => token.type.toLowerCase().indexOf('kct') !== -1);


    function allTokensToClipboard() {
        const txt = vend.tokens.map(token => getTokenText(token)).join('\n')
        navigator.clipboard.writeText(txt);
        api.success({
            message: 'Tokens copied to clipboard',
            description: "All tokens will be available with description",
        });
    }

    return <div>
        {contextHolder}
        <table>
            <tbody>
            <tr>
                <td><Button style={{width: 30}} size="small"
                            onClick={() => setExpanded(!expanded)}>{expanded ? '-' : '+'}</Button></td>
                <th><a target='_blank' rel='noreferrer' href={`https://ppex.us/receipt?vend-id=${vend.vendId}`}>VEND</a>:
                </th>
                <th>Tendered</th>
                <td>{formatCents(vend.receiptInfo.totalIncl)}</td>
                <th>Units</th>
                <td>{vend.productInfo.unitsVended}{vend.receiptInfo.productUnit}</td>
                <th>Collected</th>
                <td>{formatCents(totalCollected)}</td>
                <th>KCTs?</th>
                <td>{kctGenerated ? 'Yes' : 'No'}</td>
            </tr>
            </tbody>
        </table>
        {!expanded ? null :
            <div className="history-item-detail">
                <h3 style={{margin: 0, padding: 0}}>Transaction Detail</h3>
                <div>
                    Sneak Peek: <a target='_blank' rel='noreferrer'
                                   href={`https://ppex.us/receipt?vend-id=${vend.vendId}`}>view receipt</a>
                    <CopyOutlined
                        title="Copy to clipboard"
                        style={{marginLeft: 10}}
                        onClick={() => navigator.clipboard.writeText(`https://ppex.us/receipt?vend-id=${vend.vendId}`)}/>
                </div>
                <h4>Product Info</h4>
                <table className="info-table">
                    <tbody>
                    <tr>
                        <th>Product</th>
                        <td>{vend.productInfo.name}</td>
                    </tr>
                    <tr>
                        <th>Cost</th>
                        <td>{formatCents(vend.productInfo.cost)} (amount used to generate token from)</td>
                    </tr>
                    <tr>
                        <th>Tax</th>
                        <td>{formatCents(vend.productInfo.tax)}</td>
                    </tr>
                    <tr>
                        <th>Fee</th>
                        <td>{formatCents(vend.productInfo.feeCost)}</td>
                    </tr>
                    <tr>
                        <th>Fee Tax</th>
                        <td>{formatCents(vend.productInfo.feeTax)}</td>
                    </tr>
                    <tr>
                        <th>Steps</th>
                        <td>
                            <table className="bordered white">
                                <thead>
                                <tr>
                                    <th style={{width: 80}}>Min</th>
                                    <th style={{width: 80}}>Max</th>
                                    <th style={{width: 100}}>Rate</th>
                                    <th style={{width: 110}}>Step Units</th>
                                    <th style={{width: 110}}>Step Cost</th>
                                </tr>
                                </thead>
                                <tbody>
                                {vend.productInfo.steps.map((step, index) => <tr key={index}>
                                    <td>{step.min}</td>
                                    <td>{step.max}</td>
                                    <td>R{formatCents(step.rate)}/{vend.productInfo.unitAbbr}</td>
                                    <td>{step.units}{vend.productInfo.unitAbbr}</td>
                                    <td>R{formatCents(step.cost)}</td>
                                </tr>)}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <h4>Tokens: (<CopyOutlined onClick={allTokensToClipboard}/> all to clipboard)</h4>
                <ul>
                    {vend.tokens.map((token, index) => <li key={index}><b>{token.type}</b>:
                        <Token token={token}/>
                        {+token.units > 0 ? <> for <b>{token.units}{vend.receiptInfo.productUnit}</b></> : null}
                    </li>)}
                </ul>
                <h4>Collections ({vend.collections.length})</h4>
                {vend.collections.length === 0 ? <i>No collections</i> :
                    <table className="bordered white">
                        <thead>
                        <tr>
                            <th>Reason</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {vend.collections.map((collection, index) => <tr key={index}>
                            <td>{collection.reason}</td>
                            <td>{formatCents(collection.amountPaid)}</td>
                        </tr>)}
                        </tbody>
                    </table>
                }
            </div>
        }
    </div>;
}